<template  >
<!--    <div class=" mb-3" >-->
      <div class="row g-0">
        <div class="col-12 col-md-6 col-lg-6 col-sm-12 text-center" style="background-color: lightgrey">
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" >
            <div class="carousel-indicators">
              <button v-for="(items,i) in reviews" :hidden="items.landing_page === 'F'"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  :data-bs-slide-to="i"
                  :class="i === 0 ? 'active' : ''"
                  aria-current="true"
                  :aria-label="'Slide '+ i"
              ></button>
<!--              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>-->
            </div>
            <div class="carousel-inner" style="padding: 20px">
              <div v-for="(items,i) in reviews" :aria-hidden="items.landing_page === 'F'"
                   :class="i === 0 ? 'carousel-item active' : 'carousel-item'"  >
                <p class="texto-justificado">{{items.content}}</p>
<!--                <LoremIpsum add="36w" ></LoremIpsum>-->
                <h5 class="text-uppercase review-tittle" >{{items.first_name}} {{items.last_name}}</h5>
                <p class="review-subtittle text-uppercase">{{items.country}}</p>
              </div>
<!--              <div class="carousel-item">
                <LoremIpsum add="50w" ></LoremIpsum>
                <h5 class="text-uppercase review-tittle" >Cathy Crutcher</h5>
                <p class="review-subtittle text-uppercase">From Illinois</p>
              </div>
              <div class="carousel-item">
                <LoremIpsum add="42w" ></LoremIpsum>
                <h5 class="text-uppercase review-tittle" >Mark Dell'Olio</h5>
                <p class="review-subtittle text-uppercase">From Florida</p>
              </div>-->
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-sm-12">

          <img
              class="img-fluid rounded-start d-block w-100"
              :src="foto"
              alt="..."
              style="max-height: 100%;max-width: 700px"
          >
        </div>
      </div>
<!--    </div>-->

</template>

<script>
import LoremIpsum from 'vue-lorem-ipsum';
export default {
  name: "ReviewCard",
  components:{
    LoremIpsum,

  },
  props:['reviews','foto']
}
</script>

<style scoped>
.review-tittle {
  color: black;
  margin-top: 15px;
}
.review-subtittle {
  color: red;

}
</style>
