<template>
  <div class="text-center" style="margin-top: 15px">
<h2 >Disfruta la <span class="thr"> Experiencia</span></h2>
    <p class="texto-justificado" v-html="content"></p>
<!--    <LoremIpsum add="1p"></LoremIpsum>-->
    <div class="row">

      <div class="col col-sm-12 p-sm-4">
        <!--<a class="social-icons" href="#" target="_blank">
          <font-awesome-icon icon="fa-brands fa-facebook" size="3x" />
        </a>-->
        <a class="social-icons" href="https://www.instagram.com/theracehorseexperience/" target="_blank">
          <font-awesome-icon icon="fa-brands fa-instagram" size="3x" />
        </a>
      </div>


    </div>

  </div>
</template>

<script>
import LoremIpsum from 'vue-lorem-ipsum';
/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faFacebook,faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* add each imported icon to the library */
library.add(faTwitter,faUserSecret,faFacebook)

export default {
  name: "DescubreLaEmocion",
  components:{
    LoremIpsum,

  },
  props:['tittle','content','photo']
}
</script>

<style scoped>

.thr {
  font-family: 'centurygothic';
  color: #ff004b;
  font-size: 60px;
}

.social-icons{
  margin: 10px;
}

/*Mobile version*/
@media screen and (max-width: 768px) {
  .thr{
    font-size: 30px;
  }
  .social-icons{
    margin: 15px;
  }

}
</style>
