<template>

    <div  class="card-conocimientos">
    <img :src="imagen" class="card-img-top" alt="..." style="width: 100%;height: 420px">
    <div class="card-body">
      <h5 class="card-title text-uppercase fw-bold">{{titulo}}</h5>
      <p class="card-text texto-justificado " v-html="body"></p>

    </div>
  </div>

</template>

<script>
export default {
  name: "PrivilegiosCard",
  props:['imagen','titulo','body']
}
</script>

<style scoped>
.card-conocimientos{
  
  margin-bottom: 20px
}
/*Mobile version*/
@media screen and (max-width: 768px) {
  .card-conocimientos{
    
    margin-bottom: 10px
  }
}
</style>
