import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {includes} from "core-js/internals/array-includes";


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: HomeView
  },
  { // RUTAS REFERENTES A LA COMPRA
    path: '/comprar',
    name: 'comprar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Compras.vue')
  },
  {
    path: '/comprar-detalles/:id',
    name: 'comprar_detalles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ComprasDetalles.vue')
  },
  {
    path: '/comprar-checkout/:id/:precio/:acciones/:nombre',
    name: 'comprar-checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckOut.vue')
  },
  {
    path: '/comprar-transferencia/:id/:precio/:acciones/:nombre',
    name: 'comprar-transferencia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PagoTransferencia.vue')
  },
  {
    path: '/comprar-gracias',
    name: 'comprar-gracias',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GraciasComprar.vue')
  },
  // RUTA PARA LAS PREGUNTAS FRECUENTES
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PreguntasFrecuentes.vue')
  },
  // RUTA PARA LAS NOVEDADES
  {
    path: '/novedades',
    name: 'novedades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Novedades.vue')
  },
  // RUTA PARA EL ESTABLO
  {
    path: '/establo',
    name: 'establo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Establo.vue')
  },
  // RUTA PARA EL STUD
  {
    path: '/stud',
    name: 'stud',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stud.vue')
  },
    //RUTAS PARA EL LOGIN
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  //RUTAS PARA EL LOGOUT
  {
    path: '/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  //RUTAS PARA EL REGISTRO
  {
    path: '/register',
    name: 'registro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  //RUTAS PARA OLVIDE MI CONTRASEÑA
  {
    path: '/forgot-password',
    name: 'forgot-password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue')
  },// FIN DE LAS RUTAS DE LOGIN

  { // RUTAS PARA ADMINISTRAR LA CUENTA
    path: '/administrar-cuenta',
    name: 'administrar-cuenta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdministrarCuenta.vue')
  },
  { // RUTAS DEL USER PROFILE
    path: '/user-profile',
    name: 'user-profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PerfilUsuario.vue')
  },

  { // RUTAS DE LAS COMPRAS DEL USUARIO
    path: '/user-compras',
    name: 'user-compras',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminCompras.vue')
  },
  { // RUTAS DE LA BILLETERA VIRTUAL
    path: '/user-wallet',
    name: 'user-wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BilleteraVirtual.vue')
  },
  { // RUTAS PARA LA CONFIGURACION DE NOTIFICACIONES
    path: '/notificaciones',
    name: 'notificaciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfigurarNotificaciones.vue')
  },
  { // RUTAS PARA LOS REVIEWS
    path: '/review',
    name: 'review',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Review.vue')
  },
  { // RUTAS PARA LOS EVENTOS
    path: '/eventos',
    name: 'eventos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Eventos.vue')
  },
  { // DETALLES DE EVENTOS
    path: '/eventos-detalles/:id',
    name: 'eventos-detalles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Destalles de los Eventos" */ '../views/EventosDetalles.vue')
  },
  { // CONOCIMIENTO GENERAL
    path: '/conocimiento-general/:index',
    name: 'conocimiento-general',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Destalles de los Eventos" */ '../views/ConocimientoGeneral.vue')
  },
  {
    path: '/Terminos/',
    name: 'terminos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Terminos.vue')
  },
  {
    path: '/Politicas/',
    name: 'politicas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Politicas.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
