<template>
<div class="container">
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators p-sm-2">
      <button v-for="(items,i) in fotos"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          :data-bs-slide-to="i"
          :class="i === 0 ? 'active':''"
          aria-current="true"
          :aria-label="'Slide'+ i"
      ></button>

    </div>
    <div class="carousel-inner">
      <div v-for="(items,i) in fotos"
          :class="i === 0 ? 'carousel-item active':'carousel-item'">
        <img :src="items.photo" class="d-block w-100" alt="..." style="max-width: 1920px;max-height: 600px">
      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: "Slider",
  props:['fotos']
}
</script>

<style scoped>
.container{
  padding-top: 90px;
  padding-left: 0px;
  padding-right: 0px;
}
/*Mobile version*/
@media screen and (max-width: 768px) {
  .container{
    padding-top: 165px;
  }
}
</style>
