<template>
<div class="header">
  <!-- Menu container-->

  <div class="nav-menu">
    <font-awesome-icon
        class="icon"
        icon="fa-solid fa-bars"
        @click="showMenu"
    />
    <!--Menu content-->
    <div class="logo" >
      <router-link to="/">
        <img
            alt="Vue logo"
            src="logo_horizontal.png"
        >
      </router-link>

    </div>


    <div class="navbar navbar-expand-lg navbar-light nav-content"
         :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
    >

       <div class="navbar-collapse" id="navbarText">
         <div class="desktop">
           <router-link to="/">
             <img
                 alt="Vue logo"
                 src="logo_horizontal.png"
                  >
           </router-link>
         </div>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 nav-items">
<!--            <li >
              <router-link
                  class="nav-link"
                  aria-current="page"
                  to="/"
                  exact-active-class="active"
                  @click="showMenu"
              >Inicio
              </router-link>
            </li>-->
            <li>
              <router-link
                  class="nav-link"
                  aria-current="page"
                  to="/comprar"
                  exact-active-class="active"
                  @click="showMenu"
              >
                Compras
              </router-link>
            </li>
            <li >
              <router-link
                  class="nav-link"
                  to="/stud"
                  exact-active-class="active"
                  @click="showMenu"
              >Stud</router-link>
            </li>
            <!-- <li>
              <router-link
                  class="nav-link"
                  to="/eventos"
                  exact-active-class="active"
                  @click="showMenu"
              >Eventos</router-link>
            </li> -->
            <li >
              <router-link
                  class="nav-link"
                  to="/novedades"
                  exact-active-class="active"
                  @click="showMenu"
              >Novedades</router-link>
            </li>
            <!-- <li >
              <router-link
                  class="nav-link"
                  to="/review"
                  exact-active-class="active"
                  @click="showMenu"
              >Reseñas</router-link>
            </li> -->

            <li >
              <router-link
                  class="nav-link"
                  to="/faq"
                  exact-active-class="active"
                  @click="showMenu"
              >Preguntas Frecuentes</router-link>
            </li>
          </ul>
          <span class="navbar-text">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 nav-items">
            <li >
              <router-link
                  class="nav-link"
                  aria-current="page"
                  to="/administrar-cuenta"
                  @click="showMenu"
              >Mi Cuenta</router-link>
            </li>
            <li >
              <router-link
                  class="nav-link"
                  to="/establo"
                  exact-active-class="active"
                  @click="showMenu"
              >Mis Caballos</router-link>
            </li>
            <li  v-if="$store.state.login">
              <router-link
                  class="nav-link"
                  aria-current="page"
                  to="/login"
                  @click="logout"
              >Hola {{ $store.state.name }}, Salir </router-link>
            </li>
            <li  v-else>
              <router-link class="nav-link" aria-current="page" to="/login" @click="showMenu">Iniciar Sesion / Registrarse </router-link>
            </li>
            <li >
              <router-link class="nav-link" aria-current="page" to="/login" @click="showMenu">

              </router-link>
            </li>
          </ul>
      </span>
        </div>
    </div>


  </div>
</div>
</template>

<script>


/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faFacebook,faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import store from "@/store";


/* add each imported icon to the library */
library.add(faTwitter,faUserSecret,faFacebook,faBars)

//FUNCION PARA RECIBIR O ENVIAR INFORMACION A LA API
const http = (url,method ='POST',data) =>{
  return new Promise((resolve,reject) =>{
    fetch(url,{
      method,
      data
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error =>{
          console.log(error);
          reject(error)
        })
  })
}

export default {
  name: 'MenuPrincipal',
  data(){
    return {
      showMobileMenu: false,
    }
  },

  methods:{
    logout(){
      http('https://panel.theracehorseexperience.com/API/api.asp?Action=LOGOUT&user_id='+store.state.user_id+'&access_token='+ store.state.access_token+'&rnd='+Math.random().toString(),'POST')
          .then(data =>{
            if (data.status === 200){
              // Notificacion al Usuario
              this.$notify({
                title: "La Sesion",
                text: "Se ha Cerrado Correctamente",
                type:'success'
              });
              sessionStorage.clear()
              store.state.access_token = ''
              store.state.user_id = ''
              store.state.name = ''
              store.state.login = false
              this.$router.push('/login')

            }else {
              // Notificacion al Usuario
              this.$notify({
                title: "La Sesion",
                text: "Error: "+data.status+", Error:"+data.message,
                type:'error'
              });
            }
          })
      this.showMobileMenu=false

    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>

.header {
  position: fixed;
  left: auto;
  top: 0px;
  right: auto;
  z-index: 901;
  width: 100%;
  /*max-width: 1700px;*/
  border-bottom: 1px none #f2f4f6;
  box-shadow: 0 0 47px 0 rgba(106, 124, 143, 0.1);

}
.nav-menu {
  background-color: lightgrey;
  padding-left: 30px;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;



}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: #d80812;
}
.navbar-light .navbar-nav .nav-link.focus, .navbar-light .navbar-nav .show>.nav-link {
  color: #a00017;
}
  li {
    padding: 0 10px;
  }
.icon {
  display: none;
}
.logo{
  display: none;
}
 /*Mobile version - hidden hamburger menu*/
@media screen and (max-width: 1000px) {
  .header {
    position: fixed;
    left: auto;
    top: 0px;
    right: auto;
    z-index: 901;
    width: 100%;
    /*max-width: 1700px;*/
    border-bottom: 1px none #f2f4f6;
    box-shadow: 0 0 47px 0 rgba(106, 124, 143, 0.1);
  }
  .nav-menu {
    padding-top: 0px;
    position: absolute;
    width: 100%;
    background-color: white;

  }
  .open-menu {
    opacity: 1;
    height: 150px;

  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
    display: none;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;


  }
  .nav-items {
    flex-direction: column;
    background-color: lightgrey;
  }
  .icon {
    display: block;
    text-align: right;
    padding: 15px 10px 10px 0;
  }
  .logo{
    display: block;
    /* Center horizontally*/
    margin: 0 auto;

  }
  .desktop{
    display: none;
  }

}
</style>
