<template>

    <div class="row" style="margin-top: 15px;margin-bottom: 25px">
      <div class="col-12 col-md-6 col-lg-12 col-sm-12 text-center" >

        <div id="carouselOfertas" class="carousel carousel-dark slide" data-bs-ride="carousel" data-interval="15000">
          <div class="carousel-indicators">
            <button v-for="(items,i) in ofertas"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    :data-bs-slide-to="i"
                    :class="i === 0 ? 'active' : ''"
                    aria-current="true"
                    :aria-label="'Slide '+ i"
            ></button>
          </div>
          <div class="carousel-inner" >
            <div v-for="(items,i) in ofertas" :aria-hidden="items.landing_page === 'F'"
                 :class="i === 0 ? 'carousel-item active' : 'carousel-item'"  >
                <div class="row">
                   <div class="col-12 col-md-6 col-sm-12 col-lg-6 movil"> <!-- Solo Mostrado en el movil"-->
                     <h2 class="card-title"><span class="thr"> OFERTAS</span></h2>
                        <!-- la Foto debe ser de 700 x 400 px"-->
                        <img
                            class="img-fluid rounded-start d-block w-100"
                            :src="items.photo"
                            alt="..."
                            style="max-height: 100%;max-width: 700px"
                        >
                      </div><!-- Solo Mostrado en el movil"-->
                  <div class=" col-12 col-md-6 col-sm-12 col-lg-6 movil"><!-- Solo Mostrado en el movil"-->
                    <div class="card-body">
                      <p class="card-text texto-justificado" v-html="items.content"></p>
                    </div>
                  </div><!-- Solo Mostrado en el movil"-->
                   <div class=" col-12 col-md-6 col-sm-12 col-lg-6 pc">
                     <div class="card-body">
                       <h2 class="card-title"><span class="thr"> OFERTAS</span></h2>
                       <p class="card-text texto-justificado" v-html="items.content"></p>
                     </div>
                   </div>
                   <div class="col-12 col-md-6 col-sm-12 col-lg-6 pc">
                     <!-- la Foto debe ser de 700 x 400 px"-->
                     <img
                         class="img-fluid rounded-start d-block w-100"
                         :src="items.photo"
                         alt="..."
                         style="max-height: 100%;max-width: 700px"
                     >
                   </div>
             </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselOfertas" data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselOfertas" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>


</template>

<script>
import LoremIpsum from 'vue-lorem-ipsum';

export default {
  name: "OfertasFuturas",
  components:{
    LoremIpsum,

  },
  props:{
    ofertas:{
      type:Object,
      default:[],
      required:true
    }
  }

}
</script>

<style scoped>
.thr {
  font-family: 'centurygothic';
  color: #d80812;
  font-size: 40px;
}
.movil{
  display: none;
}

/*Mobile version - hidden hamburger menu*/
@media screen and (max-width: 768px) {
  .thr{
    font-size: 30px;
  }
  .movil{
    display: block;
  }
  .pc{
    display: none;
  }
}
</style>
