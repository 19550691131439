<template>
  <div  class="card-conocimientos">
    <img :src="imagen" class="card-img-top" alt="..." style="max-width: 420px;height: 290px">
    <div class="card-body">
      <h5 class="card-title text-uppercase fw-bold">{{titulo}}</h5>
     <router-link :to="{path:`/conocimiento-general/${id}`}" class="btn btn-danger">Conocer Más</router-link>

    </div>
  </div>
</template>

<script>

export default {
  name: "ConocimientoGeneralCard",
  props:['imagen','titulo','id']
}
</script>

<style scoped>
.card-conocimientos{
  
  margin-bottom: 20px;
  
  
}
/*Mobile version*/
@media screen and (max-width: 768px) {
  .card-conocimientos{
    
    margin-bottom: 10px
  }
}
</style>
