<template>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="cards-wrapper">
          <div class="row">
            <div class="col-md-3 col-sm-12 col-lg-3" v-for="(items,i) in ganadores" v-show="i<4">
              <div class="card" >
                <img src="https://picsum.photos/700/400?random" class="card-img-top" alt="https://picsum.photos/700/400?random">
                <div class="card-body">
                  <h5 class="card-title">{{items.titulo}}</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="carousel-item" v-if="ganadores.length >4">
        <div class="cards-wrapper">
          <div class="row">
            <div class="col-md-3 col-sm-12 col-lg-3" v-for="(items,i) in ganadores" v-show="i>4 && i<9">
              <div class="card" >
                <img src="https://picsum.photos/700/400?random" class="card-img-top" alt="https://picsum.photos/700/400?random">
                <div class="card-body">
                  <h5 class="card-title">{{items.titulo}}</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="carousel-item" v-if="ganadores.length >9">
        <div class="cards-wrapper">
          <div class="row">
            <div class="col-md-3 col-sm-12 col-lg-3" v-for="(items,i) in ganadores" v-show="i>8 && i<13">
              <div class="card" >
                <img src="https://picsum.photos/700/400?random" class="card-img-top" alt="https://picsum.photos/700/400?random" >
                <div class="card-body">
                  <h5 class="card-title">{{items.titulo}}</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>

  </div>
</template>

<script>
export default {
  name: "HerrajeGanadores",
  props:['ganadores']
}
</script>

<style scoped>
.cards-wrapper {
  display: flex;
  justify-content: center;
}
.card img {
  max-width: 100%;
  max-height: 100%;
}
.card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
  border-radius: 0;
}
.carousel-inner {
  padding: 1em;
}
.card-img-top{
  width: 340px;
  height: 300px;
}
</style>
