<template>

   <Slider
   :fotos="sliders"></Slider>

  <div>
    <DescubreLaEmocion
    :content=LP01.content
    ></DescubreLaEmocion>
  </div>
    <div>
      <OfertasFuturas
          :ofertas="LP02"
      ></OfertasFuturas>
    </div>
<!--    <div>
      <ReviewCard
          :reviews=reviews
          :foto=FotoReviews
      ></ReviewCard>
    </div>-->
    <div>
      <HistoriaCard
          :tittle=FO04.title
          :content=FO04.content
          :photo1=FO04.photo
          :photo2=foto2
      ></HistoriaCard>
    </div>
    <div class="justify-content-center" style="margin-top: 5rem">
      <div >
        <h2>¿QUÉ DEBES SABER DE LAS CARRERAS DE <span class="thr"> CABALLOS</span> ?</h2>
      </div>
   
    <div class="row row-cols-md-1 row-cols-sm-1 row-cols-lg-3"  >
      <div class="col-12 col-md-12 col-lg-4"  v-for="(item,index) of CG01" :key="item.tittle">

        <ConocimientoGeneralCard
            :titulo=item.title
            :id=index
            :imagen=item.photo
        ></ConocimientoGeneralCard>
      </div>
    </div>
  </div>
    <div class="justify-content-center" style="margin-top: 5rem">
      <div >
        <h2>BENEFICIOS DE <span class="thr"> MEMBRESÍA</span></h2>
      </div>
    </div>
    <div class="row row-cols-md-2 row-cols-sm-1 row-cols-lg-2 justify-content-center"  >
      <div class="col-12 col-md-4 col-lg-4" v-for="item of LP03">
        <PrivilegiosCard
            :titulo=item.title
            :body=item.content
            :imagen=item.photo
        ></PrivilegiosCard>
      </div>
    </div>
    <div class="justify-content-center" style="margin-top: 5rem" hidden>
      <div >
        <h2>HERRAJE DE  <span class="thr"> GANADORES</span></h2>
      </div>
    </div>
    <herraje-ganadores hidden
                       :ganadores=ganadores
    ></herraje-ganadores>

</template>

<script>
// @ is an alias to /src
import Slider from '../components/Slider.vue'
import DescubreLaEmocion from "@/components/DescubreLaEmocion";
import OfertasFuturas from "@/components/OfertasFuturas";
import ReviewCard from "@/components/ReviewCard";
import HistoriaCard from "@/components/HistoriaCard";
import PrivilegiosCard from "@/components/PrivilegiosCard";
import HerrajeGanadores from "@/components/HerrajeGanadores";
import ConocimientoGeneralCard from "@/components/ConocimientoGeneralCard";
//FUNCION PARA RECIBIR O ENVIAR INFORMACION A LA API
const http = (url,method ='GET',data) =>{
  return new Promise((resolve,reject) =>{
    fetch(url,{
      method,
      data
    })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error =>{
          console.log(error);
          reject(error)
        })
  })
}


export default {
  name: 'HomeView',
  components: {
   Slider,
    DescubreLaEmocion,
    OfertasFuturas,
    ReviewCard,
    HistoriaCard,
    PrivilegiosCard,
    HerrajeGanadores,
    ConocimientoGeneralCard
  },
  data() {
    return {
      privilegios: [
        {
          titulo: "Acompañalo",
          body: "Acompaña el crecimiento y desarrollo de tu caballo de carrera",
          imagen: "https://picsum.photos/420?random"
        },
        {
          titulo: "Lleva",
          body: "Lleva el registro de las ganancias de tu caballo de carrera",
          imagen: "https://picsum.photos/420?random"
        },
        {
          titulo: "Accede",
          body: "Acceso a la tribuna de socios para ver la carrera",
          imagen: "https://picsum.photos/420?random"
        },
        {
          titulo: "Visita",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random"
        }
      ],
      ganadores: [
        {
          titulo: "Acompañalo",
          body: "Acompaña el crecimiento y desarrollo de tu caballo de carrera",
          imagen: "https://picsum.photos/420?random",
          isActive:true
        },
        {
          titulo: "Lleva",
          body: "Lleva el registro de las ganancias de tu caballo de carrera",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Accede",
          body: "Acceso a la tribuna de socios para ver la carrera",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita 2",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita 3",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita 4",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita 5",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        },
        {
          titulo: "Visita 6",
          body: "Visita a los establos, Haras e instalaciones del Hipódromo de Monterrico",
          imagen: "https://picsum.photos/420?random",
          isActive:false
        }
      ],
      sliders:[{
        tittle: '',
        content: '',
        photo: ''
      }],
      //Descubre la emoción
      LP01: {
        tittle: '',
        content: '',
        photo: ''
      },
      //Oferta futuras
      LP02:{
        tittle:'',
        content:'',
        photo:''
      },
      //Quienes Somos
      FO04: {
        tittle: '',
        content: '',
        photo: ''
      },
      foto2:'',
      //La Propiedad Tiene sus privilegios
      LP03:[{
        tittle:'',
        content:'',
        photo:''
      }],
      //Conocimiento General
      CG01:[{
        tittle:'',
        content:'',
        photo:''
      }],
      //foto Reviews
      FotoReviews:'',
      reviews:[
        {
          id: '',
          first_name: '',
          last_name: '',
          email: '',
          title: '',
          stars: '',
          content: '',
          country: '',
          favorite_surface: '',
          date: '',
          years_as_fan: '',
          answer: ''
        }
      ]
    }
  },
  async mounted() {
    // Optener Datos dinamicos
    http('https://panel.theracehorseexperience.com/API/api.asp?Action=dynamic&code=LP')
        .then(data => {
          console.log(data.categories[1].contents)
          //Descubre la emoción
          this.LP01 = data.categories[0].contents[0]
          //Oferta futuras
          this.LP02 = data.categories[1].contents
          //foto Reviews
          this.FotoReviews = data.categories[3].contents[0].photo
          //Imagenes de los Slider
          this.sliders = data.categories[4].contents
          //La Propiedad Tiene sus privilegiospero
          this.LP03 = data.categories[2].contents
          //console.log(this.LP03)
        })
// Optener Datos FO Categories
    http('https://panel.theracehorseexperience.com/API/api.asp?Action=dynamic&code=FO')
        .then(data => {
          console.log(data)
          //Quienes Somos
          this.FO04 = data.categories[2].contents[0]
          this.foto2 = data.categories[2].contents[1].photo


        })
    // Optener Listado de Reviews
    http('https://panel.theracehorseexperience.com/API/api.asp?Action=reviews')
        .then(data => {
          //console.log(data)
          this.reviews = data.reviews


        })
    http('https://panel.theracehorseexperience.com/API/api.asp?Action=dynamic&code=CG')
        .then(data => {
          //console.log(data)
          this.CG01=data.categories[0].contents
          //console.log(this.CG01)
        })
    http('https://panel.theracehorseexperience.com/API/api.asp?Action=dynamic&code=OF')
        .then(data => {
          //console.log(data)
          //Oferta futuras
          //this.LP02 = data.categories
        })
  }
}

</script>
<style>
.thr {
  font-family: 'centurygothic';
  color: #d80812;
  font-size: 60px;

}
.centrar{
  padding-left: 10%
}

/*Mobile version*/
@media screen and (max-width: 768px) {
  .container{
    padding-top: 165px;
  }
  .centrar{
    padding-left:0
  }

  .thr {
    font-family: 'centurygothic';
    color: #d80812;
    font-size: 30px;

  }
}
</style>
