import { createStore } from 'vuex'

export default createStore({
  state: {
    access_token: sessionStorage.getItem('access_token'),
    name:sessionStorage.getItem('name'),
    user_id:sessionStorage.getItem('user_id'),
    login: sessionStorage.getItem('login'),
    referral_code: sessionStorage.getItem('referral_code')


  },

  getters: {


  },
  actions: {

  },
  modules: {
  }
})
